<template>
  <div class="summaryPageWrap en">
    <m-layout-bar>
      <div class="summaryWrap">
        <div class="imgWrap">
          <img class="banner" :src="require('../assets/image/summary_en/banner.png')" alt />
        </div>

        <div class="titleWrap">
          <span>Introduction to paradise</span>
        </div>

        <div class="contentWrap" v-html="dataInfo.info"></div>

        <!-- 轮播 -->
        <div class="swiperWrap">
          <div class="ry-content swiper">
            <div class="swiper-container sc-swiper" id="scaleSwiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(pic, index) in dataInfo.imgList" :key="index">
                  <a href="javascript:;">
                    <img style="width:1002px; height: 400px" :src="baseUrl + pic" alt />
                  </a>
                </div>
              </div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
        </div>
      </div>
    </m-layout-bar>
  </div>
</template>

<script type="text/ecmascript-6">
import LayoutBar from "@/components/LayoutBar_en";
import Swiper from "swiper";

import axios from "axios";
import config from "@/utils/config";

export default {
  components: {
    [LayoutBar.name]: LayoutBar
  },
  data() {
    return {
      banners: ["pic.png", "pic1.png", "pic2.png"],
      dataInfo: {
        imgList: new Array()
      }
    };
  },
  computed: {
    baseUrl() {
      return config.webBaseUrl;
    }
  },
  created() {},
  mounted() {
    // 获取数据
    this.getData(config.summaryUrl_en);
  },
  methods: {
    async getData(url) {
      try {
        let res = await axios.get(url);
        if (res.data.code == "0" && res.data.data instanceof Array) {
          if (res.data.data.length > 0) {
            let tempData = JSON.parse(JSON.stringify(res.data.data));

            if (tempData.length > 0) {
              this.dataInfo.info = tempData[0].info;
              this.dataInfo.imgList = tempData[0].photos.split(",");
            }
            this.$nextTick(function() {
              this.galleryScale();
            });

            //   if (!tempData[0].photos) {
            //   } else {
            //     let list = tempData[0].photos.split(",");

            //     for (let i = 0; i < list.length; i++) {
            //       this.dataInfo.imgList[i] = config.webBaseUrl + list[i];
            //     }
            //   }
            // }
            // console.log("res.data.data:", this.dataInfo);
          }
        }
      } catch (e) {
        console.log("res err:", e);
      }
    },
    galleryScale() {
      this.gallery = new Swiper("#scaleSwiper", {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        autoplay: true,
        loop: true,
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: -180,
        observer: true
      });
    }
  }
};
</script>
<style lang="scss" rel="stylesheet/scss" >
.summaryPageWrap {
  .summaryWrap {
    .contentWrap {
      p {
        line-height: 1.8;
        padding: 5px 1px;
      }
    }
  }
}
</style>
<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";

//--轮播----------------
.swiper {
  position: relative;
}

.sc-swiper {
  width: 1400px;
  cursor: pointer;
  padding: 20px 0 60px;
}

.sc-swiper .swiper-slide {
  width: 1002px;
  background-color: #fff;
  opacity: 0.7;
  transform: scale(0.7);
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -webkit-transition: transform 1s;
  -moz-transition: transform 1s;
}

.sc-swiper .swiper-slide-active {
  transform: scale(1);
  opacity: 1;
  z-index: 10;
}

.sc-swiper .swiper-slide.swiper-slide-prev {
  transform: scale(0.7);
}

.sc-swiper .swiper-slide.swiper-slide-next {
  transform: scale(0.7);
}

.sc-item {
  display: block;
  width: 1002px;
  overflow: hidden;
}

// .sc-swiper .swiper-slide-active .sc-item {
//   box-shadow: 0 6px 18px rgba(55, 139, 236, 0.29);
// }

.sc-item img {
  width: 1002px;
  height: 400px;
}

.swiperWrap {
  width: 1400px;
  height: 339px;
  margin: 0 auto 204px;
  // border: 1px solid red;
}

.swiper-button-prev,
.swiper-button-next {
  width: 59px;
  height: 90px;
  top: 35%;
  align-items: flex-end;
}

.swiper-button-prev {
  left: 20px;
}
.swiper-button-next {
  right: 20px;
}

.swiper-button-prev:after {
  position: absolute;
  // transform: translateY(100%);
  color: $main-white;
  background-image: url(~@/assets/image/summary_en/arrow_left.png) !important;
  background-size: 59px 90px;
  width: 59px;
  height: 90px;
  background-repeat: no-repeat;
  font-family: none;
  content: "";
  // background-color: red;
}

.swiper-button-next:after {
  color: $main-white;
  background-image: url(~@/assets/image/summary_en/arrow_right.png) !important;
  background-size: 59px 90px;
  width: 59px;
  height: 90px;
  background-repeat: no-repeat;
  font-family: none;
  content: "";
  // background-color: yellow;
}

.swiper-container {
  --swiper-theme-color: #ff6600;
  --swiper-navigation-color: #fff; /* 单独设置按钮颜色 */
  --swiper-navigation-size: 40px; /* 设置按钮大小 */
  // border: 1px solid green;
  padding-bottom: 150px;
}

//--------------轮播end--------------------
.summaryPageWrap {
  .summaryWrap {
    > .imgWrap {
      > .banner {
        width: 100%;
      }
    }
    > .titleWrap {
      font-size: 36px;
      font-weight: bold;
      color: $main-red;
      text-align: center;
      margin-top: 64px;
    }
    > .contentWrap {
      width: 1400px;
      min-height: 435px;
      margin: 20px auto;
      font-size: 18px;
      color: $font-grayDarkD;
      border: 1px solid transparent;
      p {
        // text-indent: 2em;
        line-height: 1.8;
        padding: 5px 1px;
      }
    }
    > .contentWrap >>> p {
      line-height: 1.8;
      padding: 5px 1px;
    }
  }
}
</style>
